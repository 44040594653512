
@import '../../sass/mdc-colors';
@import '../../sass/mdc-layout';
@import '~@material/layout-grid/mdc-layout-grid';
@import '~@material/chips/mdc-chips';

@mixin category-background($image, $overlayColor: #000000) {
    background-image: linear-gradient(rgba(#000, 0.5), rgba(#000, 0.5)), url('../../static/' + $image);
    background-size: cover;
    color: #ffffff;
}

.mvx-category {
    background: #f5f5f5;
    border: 1px solid #e4e3e3;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    flex-direction: column;
    margin: 1rem;
    padding: .9rem;
    height: 290px;
    width: 350px;
    overflow-y: auto;
    overflow-x: hidden;

    &--direct {
        cursor: pointer;
        justify-content: center;
    }

    &--7 {
        @include category-background('bebe.jpg');
    }

    &--17 {
        @include category-background('boisson.jpg');
    }

    &--15 {
        @include category-background('entretien.jpg');
    }

    &--11 {
        @include category-background('epicerie-salee.jpg');
    }

    &--9 {
        @include category-background('epicerie-sucree.jpg');
    }

    &--13, &--70 {
        @include category-background('hygiene-beaute.jpg');
    }

    &--19 {
        @include category-background('maison-loisir.jpg');
    }

    &--3 {
        @include category-background('surgeles.jpg');
    }

    &--21 {
        @include category-background('quincaillerie.jpg');
    }

    &--2006 {
        @include category-background('petfood.jpg');
    }

    &--outlined {
        background: $mdc-theme-primary;
    }

    i {
        font-size: 2rem;
        margin-bottom: .5rem;
    }

    &__title {
        margin-bottom: 1rem;
    }

    &__subs {
        &__sub {
            width: 100%;
            display: flex;
            justify-content: center;

            .mdc-chip__text {
                width: 100%;
            }

            &__label {
                text-transform: capitalize;
            }
        }
    }
}
